<style src="../../assets/css/inputeo.css" scoped></style>

<style scoped>
    .checkbox-button.orange input:checked + div {
        background: linear-gradient(90deg, #ff6b00, #ff9100);
    }

    .checkbox-button.grey input:checked + div {
        background: linear-gradient(90deg, #646e78 ,  #a0a5aa);
    }
</style>

<template>
    <div class="checkbox-button-container">
        <div :class="`checkbox-button ${color}`">
            <input
                :id="id"
                v-bind="$attrs"
                type="checkbox"
                v-model="value"
                @change="onChange"
            />
            <div>
                <font-awesome-icon :icon="['fas', 'check']" />
            </div>
        </div>
        <label v-if="label" :for="id">{{ label }}</label>
    </div>
</template>

<script>
    export default {
        name: 'BaseCheckbox',
        props: {
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                id: null,
                value: this.modelValue
            }
        }, 
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
        },
        methods: {
            onChange(event) {
                this.$emit('onChange', event.target.checked);
            }
        }
    }
</script>