<style scoped>
    .topbar-right-container {
        display: flex;
        align-items: center;
        position: fixed;
        right: 30px;
        top: 16px;
        z-index: 100;
    }
</style>

<template>
    <div class="topbar-right-container">
        <slot name="custom-topbar"></slot>
    </div>
</template>

<script>
    export default {
        name: 'CustomTopbar'
    }
</script>