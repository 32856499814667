<style src="../../assets/css/inputeo.css" scoped></style>

<template>
    <div class="radio-button-container" :class="disabled ? 'disabled' : ''">
        <div class="radio-button">
            <input
                :id="id"
                type="radio"
                @change="onChange"
                :modelValue="modelValue"
                :mode="mode"
                :name="name"
                :value="value"
                :disabled="disabled"
            />
            <div>
                <font-awesome-icon :icon="['fas', 'check']" />
            </div>
        </div>
        <label v-if="label" :for="id">{{ label }}</label>
    </div>
</template>

<script>
    export default {
        name: 'BaseRadioButton',
        props: {
            value: {
                required: true
            },
            mode: {
                default: 'string'
            },
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            modelValue: {
                required: true
            },
            index: {
                type: Number,
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                id: null
            }
        }, 
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
        },
        methods: {
            onChange(event) {
                if(this.mode === 'boolean'){
                    this.$emit('update:modelValue', event.target.checked);
                    this.$emit('onChange', {
                        name: this.name,
                        index: this.index
                    });
                }
                else{
                    this.$emit('update:modelValue', event.target.value);    
                }
            }
        }
    }
</script>