<style scoped>
    .filter {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
    }
    
    .container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .cards {
        display: flex;
        flex-direction: row;
    }

    .card {
        padding: 30px 0 0 0;
    }

    .card:not(:last-child) {
        margin-right: 50px;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        padding: 0 30px;
    }

    .card-header-title {
        color: rgb(50, 60, 70);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .searchbar {
        display: flex;
        height: 65px;
        position: relative;
        margin-bottom: 30px;
    }

    .searchbar-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        background-color: #505A64;
        width: 65px;
        z-index: 2;
    }

    .searchbar-icon:after {
        position: absolute;
        right: -19px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 65px solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 20px solid #505A64;
    }

    .searchbar-icon svg {
        color: white;
        width: 22px;
        height: 22px;
        margin-right: 12px;
    }

    .searchbar .inputeo {
        height: 100%;
        flex: 1;
        z-index: 1;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .infinite-list {
        box-sizing: border-box;
        padding: 0 30px 30px 30px;
        height: calc(100vh - 380px);
    }

    :deep(.searchbar .inputeo input::placeholder) {
        font-weight: 500;
        color: rgb(100, 110, 120);
    }

    .infinite-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    .infinite-list-item-name {
        font-size: 14px;
        color: rgb(30, 40, 50);
        font-weight: 500;
    }

    .infinite-list-item-buttons > :not(:last-child) {
        margin-right: 10px;
    }

    table {
        border-collapse: collapse;
        margin: 0 8mm;
        font-size: 12px;
        color: rgb(50, 60, 70);
        width: calc(100% - 16mm);
    }

    table thead {
        background-color: #0D3B70;
        color: white;
        text-align: left;
        font-size: 11px;
    }

    table thead th {
        padding: 2mm 5mm 2mm 5mm;
        font-weight: 600;
        text-transform: uppercase;
    }

    table tbody {
        background-color: rgb(236, 240, 244);
    }

    table tbody td {
        padding: 2.5mm 5mm 2.5mm 5mm;
        font-weight: 500;
    }

    table tbody tr td:not(:last-child) {
        border-right: 1px solid rgb(200, 210, 220);
    }

    #spindle_type_stats_modal p {
        padding: 20px;
    }

    #spindle_in_subcontracting_modal p {
        margin: 30px;
    }

    .sub-contracting-pieces {
        border: 1px solid #0D3B70;
        margin-top: 5px;
    }

    .sub-contracting-pieces .sub-titles {
        background-color: #cfdbe8;
        text-align: center;
    }

    .sub-contracting-pieces .sub-contracting-articles {
        background-color: rgb(246, 248, 250);
        text-align: center;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Pièces en sous-traitance" @click="openSpindleInSubcontractingModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <div class="container">
        <div class="filter">
            <BaseCheckbox label="Afficher les éléments désactivés" @onChange="onDisplayDeletedFilterChange"/>
        </div>

        <div class="cards">
            <Card>
                <div class="card-header">
                    <div class="card-header-title">Marques de broche</div>
                    <BaseButton class="small-button orange-button" buttonText="" @click="displayCreateSpindleBrandModal" title="Ajouter">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </template>
                    </BaseButton>
                </div>

                <div class="searchbar">
                    <div class="searchbar-icon">
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </div>
                    <BaseInput
                        v-model="spindleBrandNameSearchValue"
                        placeholder="Rechercher une marque"
                        :onKeyUp="handleSearchBarInput"
                    >
                    </BaseInput>
                </div>

                <InfiniteList
                    name="spindleBrands"
                    :apiRoute="listSpindleBrandsApiRoute"
                    :apiParams="{
                        deleted: displayDeleted
                    }"
                    :itemsPerPage="itemsPerPage"
                    @onScrollBottomReached="loadLists"
                >
                    <template v-slot:itemContainer="{item}">
                        <div class="infinite-list-item">
                            <div class="infinite-list-item-name">{{ item.name }}</div>
                            <div class="infinite-list-item-buttons">
                                <BaseButton class="small-button blue-button" buttonText="" @click="function() { displayEditSpindleBrandModal(item) }" title="Modifier">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'pen']" />
                                    </template>
                                </BaseButton>
                                <BaseButton class="small-button grey-button" buttonText="" @click="function() { deleteOrEnableSpindleBrand(item) }" :title="item.deleted ? 'Activer': 'Désactiver'">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', item.deleted ? 'trash-restore' : 'trash']" />
                                    </template>
                                </BaseButton>
                            </div>
                        </div>
                    </template>
                </InfiniteList>
            </Card>
            <Card>
                <div class="card-header">
                    <div class="card-header-title">Types de broche</div>
                    <BaseButton class="small-button orange-button" buttonText="" @click="displayCreateSpindleTypeModal" title="Ajouter">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </template>
                    </BaseButton>
                </div>

                <div class="searchbar">
                    <div class="searchbar-icon">
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </div>
                    <BaseInput
                        v-model="spindleTypeNameSearchValue"
                        placeholder="Rechercher un type"
                        :onKeyUp="handleSearchBarInput"
                    >
                    </BaseInput>
                </div>

                <InfiniteList
                    name="spindleTypes"
                    :apiRoute="listSpindleTypesApiRoute"
                    :apiParams="{
                        deleted: displayDeleted
                    }"
                    :itemsPerPage="itemsPerPage"
                    @onScrollBottomReached="loadLists"
                >
                    <template v-slot:itemContainer="{item}">
                        <div class="infinite-list-item">
                            <div class="infinite-list-item-name">{{ item.name }}</div>
                            <div class="infinite-list-item-buttons">
                                <BaseButton class="small-button green-button" buttonText="" @click="function() { openSpindleTypeStatsModal(item) }" title="Analytique">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'chart-line']"/>
                                    </template>
                                </BaseButton>
                                <BaseButton class="small-button blue-button" buttonText="" @click="function() { displayEditSpindleTypeModal(item) }" title="Modifier">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'pen']" />
                                    </template>
                                </BaseButton>
                                <BaseButton class="small-button grey-button" buttonText="" @click="function() { deleteOrEnableSpindleType(item) }" :title="item.deleted ? 'Activer': 'Désactiver'">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', item.deleted ? 'trash-restore' : 'trash']" />
                                    </template>
                                </BaseButton>
                            </div>
                        </div>
                    </template>
                </InfiniteList>
            </Card>
            <Card>
                <div class="card-header">
                    <div class="card-header-title">Numéros de série</div>
                    <BaseButton class="small-button orange-button" buttonText="" @click="displayCreateSpindleModal" title="Ajouter">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </template>
                    </BaseButton>
                </div>

                <div class="searchbar">
                    <div class="searchbar-icon">
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </div>
                    <BaseInput
                        v-model="serialNumberSearchValue"
                        placeholder="Rechercher un numéro de série"
                        :onKeyUp="handleSearchBarInput"
                    >
                    </BaseInput>
                </div>

                <InfiniteList
                    name="spindles"
                    :apiRoute="listSpindlesApiRoute"
                    :apiParams="{
                        deleted: displayDeleted
                    }"
                    :itemsPerPage="itemsPerPage"
                    @onScrollBottomReached="loadLists"
                >
                    <template v-slot:itemContainer="{item}">
                        <div class="infinite-list-item">
                            <div class="infinite-list-item-name">{{ item.serialNumber }}</div>
                            <div class="infinite-list-item-buttons">
                                <BaseButton class="small-button blue-button" buttonText="" @click="function() { displayEditSpindleModal(item) }" title="Modifier">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'pen']" />
                                    </template>
                                </BaseButton>
                                <BaseButton class="small-button grey-button" buttonText="" @click="function() { deleteOrEnableSpindle(item) }" :title="item.deleted ? 'Activer': 'Désactiver'">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', item.deleted ? 'trash-restore' : 'trash']" />
                                    </template>
                                </BaseButton>
                            </div>
                        </div>
                    </template>
                </InfiniteList>
            </Card>
        </div>
    </div>
    

    <Modal v-show="displayModalCreateOrEditSpindleBrand === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UNE MARQUE DE BROCHE</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UNE MARQUE DE BROCHE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="spindleBrand.name" 
                        label="Libellé" 
                        type="text" 
                        name="name" 
                        validator="text" 
                        :error="spindleBrandFormErrors?.name?.error?.message" 
                        @onChange="onSpindleBrandFormInputChange" 
                        :displayError="displayError" 
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeSpindleBrandModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitSpindleBrandModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Modal v-show="displayModalCreateOrEditSpindleType === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UN TYPE DE BROCHE</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UN TYPE DE BROCHE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput
                        v-model="spindleType.name" 
                        label="Libellé" 
                        type="text" 
                        name="name" 
                        validator="text" 
                        :error="spindleTypeFormErrors?.name?.error?.message" 
                        @onChange="onSpindleTypeFormInputChange" 
                        :displayError="displayError" 
                    />
                </div>
                <div>
                    <BaseSelect 
                        v-model="spindleType.spindleBrandId"
                        label="Sélectionnez une marque" 
                        name="spindleBrandId"
                        api="spindleBrand/search"
                        minChars="2"
                        fieldValue="id" 
                        fieldLabel="name"
                        :error="spindleTypeFormErrors?.spindleBrandId?.error?.message"
                        @onChange="onSpindleTypeFormInputChange" 
                        :displayError="displayError"
                        :searchable="true"
                    />                    
                    <div>ou</div>
                    <BaseInput 
                        v-model="spindleType.spindleBrandName" 
                        label="Créez une marque en saisissant son libellé" 
                        type="text" 
                        name="spindleBrandName" 
                        @onChange="onSpindleTypeFormInputChange" 
                        :required="false"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeSpindleTypeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitSpindleTypeModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Modal v-show="displayModalCreateOrEditSpindle === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditMode === 'create' ? 'plus' : 'edit']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditMode === 'create'">CRÉER UN NUMERO DE SÉRIE</div>
            <div v-show="createOrEditMode === 'edit'">MODIFIER UN NUMERO DE SÉRIE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="spindle.serialNumber" 
                        label="Numéro de série" 
                        type="text" 
                        name="serialNumber" 
                        validator="text" 
                        :error="spindleFormErrors?.serialNumber?.error?.message" 
                        @onChange="onSpindleFormInputChange" 
                        :displayError="displayError" 
                    />

                    <RadioButtonContainer 
                        label="Modèle"
                        name="model" 
                        :error="spindleFormErrors?.model?.error?.message" 
                        :displayError="displayError" 
                        @onChange="onSpindleFormInputChange" 
                        :required="true"
                        v-model="spindle.model"
                    >
                        <BaseRadioButton v-model="spindle.model" label="Mécanique" name="model" value="mechanical"></BaseRadioButton>
                        <BaseRadioButton v-model="spindle.model" label="Électrobroche" name="model" value="electrospindle"></BaseRadioButton>
                        <BaseRadioButton v-model="spindle.model" label="Autre" name="model" value="other"></BaseRadioButton>
                    </RadioButtonContainer>
                </div>
                <div>
                    <BaseSelect 
                        v-model="spindle.spindleBrandId"
                        label="Sélectionnez une marque" 
                        name="spindleBrandId"
                        api="spindleBrand/search"
                        minChars="2"
                        fieldValue="id" 
                        fieldLabel="name"
                        :error="spindleFormErrors?.spindleBrandId?.error?.message"
                        @onChange="onSpindleFormInputChange" 
                        :displayError="displayError"
                        :searchable="true"
                    />                  
                    <div>ou</div>
                    <BaseInput 
                        v-model="spindle.spindleBrandName" 
                        label="Créez une marque en saisissant son libellé" 
                        type="text" 
                        name="spindleBrandName" 
                        @onChange="onSpindleFormInputChange" 
                        :required="false"
                    />
                </div>
                <div>
                    <BaseSelect 
                        v-model="spindle.spindleTypeId"
                        label="Sélectionnez un type" 
                        name="spindleTypeId"
                        api="spindleType/search"
                        :apiParams="{
                            spindleBrandId: spindle.spindleBrandId?.value
                        }"
                        minChars="2"
                        fieldValue="id" 
                        fieldLabel="name"
                        :error="spindleFormErrors?.spindleTypeId?.error?.message"
                        @onChange="onSpindleFormInputChange" 
                        :displayError="displayError"
                        :searchable="true"
                    />                   
                    <div>ou</div>
                    <BaseInput 
                        v-model="spindle.spindleTypeName" 
                        label="Créez un type en saisissant son libellé" 
                        type="text" 
                        name="spindleTypeName" 
                        @onChange="onSpindleFormInputChange" 
                        :required="false"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeSpindleModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitSpindleModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Modal
        id="spindle_type_stats_modal"
        v-show="displaySpindleTypeStats"
        @onClose="closeSpindleTypeStatsModal"
        v-bind:hide-icon="true"
    >
        <template v-slot:modalTitle>
            STATISTIQUES
        </template>
        <template v-slot:modalContent>
            <table v-if="currentSpindleTypeHistory.length > 0">
                <thead>
                <tr>
                    <th>Statut</th>
                    <th>Temps moyen</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in currentSpindleTypeHistory">
                    <td v-if="row">{{ row.statusLabel }}</td>
                    <td v-if="row">{{ row.timeByStatus }}</td>
                </tr>
                </tbody>
            </table>
            <p v-else>
                Aucune donnée n'est présente pour ce type de broche.
            </p>
        </template>
        <template v-slot:modalButtons>
            <BaseButton @click="closeSpindleTypeStatsModal" buttonText="Fermer" class="white-button">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton 
                @click="exportSpindleTypeStats"
                buttonText="Exporter"
                class="orange-button"
            >
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'file-export']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal
        id="spindle_in_subcontracting_modal"
        v-show="displaySpindleInSubcontractingModal"
        @onClose="closeSpindleInSubcontractingModal"
        v-bind:hide-icon="true"
    >
        <template v-slot:modalTitle>
            PIECES EN SOUS-TRAITANCE
        </template>
        <template v-slot:modalContent>
            <div v-if="spindlesInSubcontracting != null">
                <div v-if="spindlesInSubcontracting.length > 0">
                <table>
                    <thead>
                    <tr>
                        <th style="width:25%">Numéro de l'affaire</th>
                        <th style="width:25%">Marque de broche</th>
                        <th style="width:25%">Type de broche</th>
                        <th style="width:25%">Date de mise en sous-traitance</th>
                    </tr>
                    </thead>
                </table>
                <table class="sub-contracting-pieces" v-for="row in spindlesInSubcontracting">
                    <tbody>
                        <tr>
                            <td style="width:25%" v-if="row">{{ row.affairNumber }}</td>
                            <td style="width:25%" v-if="row">{{ row.spindleBrand }}</td>
                            <td style="width:25%" v-if="row">{{ row.spindleType }}</td>
                            <td style="width:25%" v-if="row">{{ row.date }}</td>
                        </tr>
                        <tr class="sub-titles" v-if="row.articles.length > 0">
                            <td colspan="1">Quantité</td>
                            <td colspan="2">Label</td>
                            <td colspan="1">Reception estimée</td>
                        </tr>
                        <tr v-for="article in row.articles.filter(article => article != null)">
                            <td colspan="1" class="sub-contracting-articles">{{ article.quantity }}</td>
                            <td colspan="2" class="sub-contracting-articles">{{ article.label }}</td>
                            <td colspan="1" class="sub-contracting-articles">{{ article.averageReceipt }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <p v-else>
                    Aucune pièce n'est actuellement en sous-traitance.
                </p>
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton @click="closeSpindleInSubcontractingModal" buttonText="Fermer" class="white-button">
            </BaseButton>
        </template>
    </Modal>

    <Dialog ref="dialog" />
</template>

<script>
    import Card from "../../components/App/Card"
    import BaseCheckbox from "../../components/Base/BaseCheckbox"
    import BaseButton from "../../components/Base/BaseButton"
    import BaseInput from "../../components/Base/BaseInput"
    import BaseSelect from '../../components/Base/BaseSelect'
    import RadioButtonContainer from "../../components/App/RadioButtonContainer"
    import BaseRadioButton from "../../components/Base/BaseRadioButton"
    import InfiniteList from "../../components/App/InfiniteList"
    import Modal from '../../components/App/Modal'
    import Dialog from '../../components/App/Dialog'
    import axios from 'axios'
    import changeTypeEnum from '../../enums/changeTypeEnum'
    import affairStatusEnum from '../../enums/affairStatusEnum';
    import CustomTopbar from "@/components/Topbar/CustomTopbar";

    export default {
        name: 'Spindles',
        components: {
            Card,
            BaseCheckbox,
            BaseButton,
            BaseInput,
            BaseSelect,
            InfiniteList,
            Modal,
            Dialog,
            RadioButtonContainer,
            BaseRadioButton,
            CustomTopbar
        },
        data() {
            return {
                context: null,
                displayModalCreateOrEditSpindleBrand: false,
                displayModalCreateOrEditSpindleType: false,
                displayModalCreateOrEditSpindle: false,
                createOrEditMode: null,
                spindleBrand: null,
                spindleType: null,
                spindle: null,
                displayError: false,
                spindleBrandFormErrors: {},
                spindleTypeFormErrors: {},
                spindleFormErrors: {},
                searchSpindleTypeUrl: null,
                spindleBrandNameSearchValue: '',
                spindleTypeNameSearchValue: '',
                serialNumberSearchValue: '',
                listSpindleBrandsApiRoute: '',
                listSpindleTypesApiRoute: '',
                listSpindlesApiRoute: '',
                displayDeleted: false,
                keyCount: 0,
                page: 1,
                itemsPerPage: 20,
                searchTimeout: null,
                displaySpindleTypeStats: false,
                currentSpindleTypeHistory: [],
                affairStatusEnum,
                displaySpindleInSubcontractingModal: false,
                spindlesInSubcontracting: null,
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.listSpindleBrandsApiRoute = 'spindleBrand/list';
            this.listSpindleTypesApiRoute = 'spindleType/list';
            this.listSpindlesApiRoute = 'spindle/list';

            this.resetSpindleBrand();
            this.resetSpindleType();
            this.resetSpindle();
        },
        mounted() {
            switch (this.$route.query?.action) {
                case 'addSpindleBrand':
                    this.displayCreateSpindleBrandModal();
                break;
                case 'addSpindleType':
                    this.displayCreateSpindleTypeModal();
                break;
            }
        },
        methods: {
            closeSpindleBrandModal() {
                this.resetSpindleBrand();
                this.displayModalCreateOrEditSpindleBrand = false;
                this.displayError = false;
            },
            submitSpindleBrandModal() {
                if (this.createOrEditMode === 'create') {
                    this.createSpindleBrand();
                } else if (this.createOrEditMode === 'edit') {
                    this.editSpindleBrand();
                }
            },
            displayCreateSpindleBrandModal() {
                this.createOrEditMode = 'create';
                this.resetSpindleBrand();
                this.displayModalCreateOrEditSpindleBrand = true;
            },
            displayEditSpindleBrandModal(spindleBrand) {
                this.createOrEditMode = 'edit';
                this.spindleBrand = JSON.parse(JSON.stringify(spindleBrand));
                this.displayModalCreateOrEditSpindleBrand = true;
            },
            resetSpindleBrand() {
                this.spindleBrand = {
                    name: null
                };
            },
            createSpindleBrand() {
                if (!this.checkSpindleBrandForm()) return;

                axios
                .post('spindleBrand/create', this.spindleBrand, { 
                    toastSuccessMessage: `Marque créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleBrandModal();
                })
                .catch(() => {});
            },
            editSpindleBrand() {
                if (!this.checkSpindleBrandForm()) return;

                axios
                .put('spindleBrand/update', this.spindleBrand, { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleBrandModal();
                })
                .catch(() => {});
            },
            deleteOrEnableSpindleBrand(spindleBrand) {
                let deleted = !spindleBrand.deleted;

                axios
                .put('spindleBrand/changeDeleted/' + spindleBrand.id, {
                    deleted: deleted
                }, { 
                    toastSuccessMessage: `${deleted ? 'Désactivation' : 'Activation'} effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                })
                .catch(() => {});
            },
            onSpindleBrandFormInputChange(input) {
                if (input.error.message !== null) {
                    this.spindleBrandFormErrors[input.name] = input;
                } else {
                    delete this.spindleBrandFormErrors[input.name];
                }
            },
            checkSpindleBrandForm() {
                this.displayError = true;

                return Object.keys(this.spindleBrandFormErrors).length === 0;
            },
            closeSpindleTypeModal() {
                this.resetSpindleType();
                this.displayModalCreateOrEditSpindleType = false;
                this.displayError = false;
            },
            submitSpindleTypeModal() {
                if (this.createOrEditMode === 'create') {
                    this.createSpindleType();
                } else if (this.createOrEditMode === 'edit') {
                    this.editSpindleType();
                }
            },
            displayCreateSpindleTypeModal() {
                this.createOrEditMode = 'create';
                this.resetSpindleType();
                this.displayModalCreateOrEditSpindleType = true;
            },
            displayEditSpindleTypeModal(spindleType) {
                this.createOrEditMode = 'edit';
                this.prepareEditSpindleType(spindleType);
                this.displayModalCreateOrEditSpindleType = true;
            },
            resetSpindleType() {
                this.spindleType = {
                    name: null,
                    spindleBrandId: null,
                    spindleBrandName: null
                };
            },
            prepareEditSpindleType(spindleType) {
                this.spindleType = JSON.parse(JSON.stringify(spindleType));
                this.spindleType.spindleBrandId = {
                    value: spindleType.spindleBrand.id,
                    label: spindleType.spindleBrand.name,
                };
                this.spindleType.spindleBrandName = "";
            },
            formateSpindleType(spindleType) {
                spindleType = JSON.parse(JSON.stringify(spindleType));
                if(spindleType.spindleBrandId !== null) {
                    spindleType.spindleBrandId = spindleType.spindleBrandId.value;
                }
                return spindleType;
            },
            createSpindleType() {
                if (!this.checkSpindleTypeForm()) return;

                axios
                .post('spindleType/create', this.formateSpindleType(this.spindleType), { 
                    toastSuccessMessage: `Type créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleTypeModal();
                })
                .catch(() => {});
            },
            editSpindleType() {
                if (!this.checkSpindleTypeForm()) return;

                axios
                .put('spindleType/update', this.formateSpindleType(this.spindleType), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleTypeModal();
                })
                .catch(() => {});
            },
            deleteOrEnableSpindleType(spindleType) {
                let deleted = !spindleType.deleted;

                axios
                .put('spindleType/changeDeleted/' + spindleType.id, {
                    deleted: deleted
                }, { 
                    toastSuccessMessage: `${deleted ? 'Désactivation' : 'Activation'} effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                })
                .catch(() => {});
            },
            onSpindleTypeFormInputChange(input) {
                if(input.name === 'spindleBrandId' && Boolean(this.spindleType.spindleBrandId)) {
                    this.spindleType.spindleBrandName = null;
                }
                else if(input.name === 'spindleBrandName' && Boolean(this.spindleType.spindleBrandName)) {
                    this.spindleType.spindleBrandId = null;
                }

                if (input.error.message !== null) {
                    this.spindleTypeFormErrors[input.name] = input;
                } else {
                    delete this.spindleTypeFormErrors[input.name];
                }

                if (!this.spindleTypeFormErrors.spindleBrandId || this.spindleTypeFormErrors.spindleBrandId.error.type === 'custom') {
                    if (!Boolean(this.spindleType.spindleBrandId) && !Boolean(this.spindleType.spindleBrandName)) {
                        this.spindleTypeFormErrors.spindleBrandId = {
                            name: input.name,
                            value: this.spindleType.spindleBrandId,
                            error: {
                                type: 'custom',
                                message: `Vous devez renseigner une marque`
                            }
                        }
                    } else {
                        delete this.spindleTypeFormErrors.spindleBrandId;
                    }
                }
            },
            checkSpindleTypeForm() {
                this.displayError = true;

                return Object.keys(this.spindleTypeFormErrors).length === 0;
            },
            closeSpindleModal() {
                this.resetSpindle();
                this.displayModalCreateOrEditSpindle = false;
                this.displayError = false;
            },
            submitSpindleModal() {
                if (this.createOrEditMode === 'create') {
                    this.createSpindle();
                } else if (this.createOrEditMode === 'edit') {
                    this.editSpindle();
                }
            },
            displayCreateSpindleModal() {
                this.createOrEditMode = 'create';
                this.resetSpindle();
                this.displayModalCreateOrEditSpindle = true;
            },
            displayEditSpindleModal(spindle) {
                this.createOrEditMode = 'edit';
                this.prepareEditSpindle(spindle);
                this.displayModalCreateOrEditSpindle = true;
            },
            resetSpindle() {
                this.spindle = {
                    serialNumber: null,
                    model: null,
                    spindleTypeId: null,
                    spindleTypeName: null,
                    spindleBrandId: null,
                    spindleBrandName: null
                };
            },
            prepareEditSpindle(spindle) {
                this.spindle = JSON.parse(JSON.stringify(spindle));
                this.spindle.spindleTypeId = {
                    value: spindle.spindleType.id,
                    label: spindle.spindleType.name,
                };
                this.spindle.spindleTypeName = "";
                this.spindle.spindleBrandId = {
                    value: spindle.spindleType.spindleBrand.id,
                    label: spindle.spindleType.spindleBrand.name,
                };
                this.spindle.spindleBrandName = "";
            },
            formateSpindle(spindle) {
                spindle = JSON.parse(JSON.stringify(spindle));
                if(spindle.spindleTypeId !== null) {
                    spindle.spindleTypeId = spindle.spindleTypeId.value;
                }
                if(spindle.spindleBrandId !== null) {
                    spindle.spindleBrandId = spindle.spindleBrandId.value;
                }
                return spindle;
            },
            createSpindle() {
                if (!this.checkSpindleForm()) return;

                axios
                .post('spindle/create', this.formateSpindle(this.spindle), { 
                    toastSuccessMessage: `Numéro de série créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleModal();
                })
                .catch(() => {});
            },
            editSpindle() {
                if (!this.checkSpindleForm()) return;

                axios
                .put('spindle/update', this.formateSpindle(this.spindle), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                    this.closeSpindleModal();
                })
                .catch(() => {});
            },
            deleteOrEnableSpindle(spindle) {
                let deleted = !spindle.deleted;

                axios
                .put('spindle/changeDeleted/' + spindle.id, {
                    deleted: deleted
                }, { 
                    toastSuccessMessage: `${deleted ? 'Désactivation' : 'Activation'} effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.reloadLists();
                })
                .catch(() => {});
            },
            onSpindleFormInputChange(input) {
                switch (input.name) {
                    case 'spindleBrandId':
                        if(Boolean(this.spindle.spindleBrandId)) {
                            this.spindle.spindleBrandName = null;
                        }

                        if (input.changeType === changeTypeEnum.USER) this.spindle.spindleTypeId = null;
                        break;
                    case 'spindleTypeId':
                        if(Boolean(this.spindle.spindleTypeId)) {
                            this.spindle.spindleTypeName = null;
                            this.spindle.spindleBrandName = null;
                            if(this.spindle.spindleTypeId.data != null) {
                                this.spindle.spindleBrandId = {
                                    value: this.spindle.spindleTypeId.data.spindleBrand.id,
                                    label: this.spindle.spindleTypeId.data.spindleBrand.name
                                }
                            }
                        }
                        break;
                    case 'spindleBrandName': {
                        if(Boolean(this.spindle.spindleBrandName)) {
                            this.spindle.spindleBrandId = null;
                            this.spindle.spindleTypeId = null;
                        }
                        break;
                    }
                    case 'spindleTypeName': {
                        if(Boolean(this.spindle.spindleTypeName)) {
                            this.spindle.spindleTypeId = null;
                        }
                        break;
                    }
                    default:
                        break;
                }

                if (input.error.message !== null) {
                    this.spindleFormErrors[input.name] = input;
                } else {
                    delete this.spindleFormErrors[input.name];
                }

                if (!this.spindleFormErrors.spindleBrandId || this.spindleFormErrors.spindleBrandId.error.type === 'custom') {
                    if (!Boolean(this.spindle.spindleBrandId) && !Boolean(this.spindle.spindleBrandName)) {
                        this.spindleFormErrors.spindleBrandId = {
                            name: 'spindleBrandId',
                            value: this.spindle.spindleBrandId,
                            error: {
                                type: 'custom',
                                message: `Vous devez renseigner une marque`
                            }
                        }
                    } else {
                        delete this.spindleFormErrors.spindleBrandId;
                    }
                }

                if (!this.spindleFormErrors.spindleTypeId || this.spindleFormErrors.spindleTypeId.error.type === 'custom') {
                    if (!Boolean(this.spindle.spindleTypeId) && !Boolean(this.spindle.spindleTypeName)) {
                        this.spindleFormErrors.spindleTypeId = {
                            name: 'spindleTypeId',
                            value: this.spindle.spindleTypeId,
                            error: {
                                type: 'custom',
                                message: `Vous devez renseigner un type`
                            }
                        }
                    }
                    else {
                        delete this.spindleFormErrors.spindleTypeId;
                    }
                }
            },
            checkSpindleForm() {
                this.displayError = true;

                let hasSpindleBrand = Boolean(this.spindle.spindleBrandId) || Boolean(this.spindle.spindleBrandName);
                let hasSpindleType = Boolean(this.spindle.spindleTypeId) || Boolean(this.spindle.spindleTypeName);

                return Object.keys(this.spindleFormErrors).length === 0 && hasSpindleBrand && hasSpindleType;
            },
            handleSearchBarInput() {
                if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(this.checkSearchBarValues, 250);
            },
            checkSearchBarValues() {
                this.page = 1;

                // si un des champs de recherche est rempli, on charge les infinite list avec un seul appel à l'api
                if(Boolean(this.spindleBrandNameSearchValue) || Boolean(this.spindleTypeNameSearchValue) || Boolean(this.serialNumberSearchValue)) {
                    this.listSpindleBrandsApiRoute = '';
                    this.listSpindleTypesApiRoute = '';
                    this.listSpindlesApiRoute = '';

                    this.loadLists();
                }
                // sinon, chaque liste a son propre appel à l'api pour charger ses données
                else {
                    this.listSpindleBrandsApiRoute = 'spindleBrand/list';
                    this.listSpindleTypesApiRoute = 'spindleType/list';
                    this.listSpindlesApiRoute = 'spindle/list';
                }
            },
            loadLists() {
                let listParams = {
                    deleted: this.displayDeleted,
                    page: this.page,
                    numberItems: this.itemsPerPage
                };

                if(Boolean(this.spindleBrandNameSearchValue)) {
                    listParams.spindleBrandName = this.spindleBrandNameSearchValue;
                }

                if(Boolean(this.spindleTypeNameSearchValue)) {
                    listParams.spindleTypeName = this.spindleTypeNameSearchValue;
                }

                if(Boolean(this.serialNumberSearchValue)) {
                    listParams.serialNumber = this.serialNumberSearchValue;
                }

                const params = new URLSearchParams(listParams);

                axios.get('spindleBrand/listWithTypesAndSpindles' + '?' + params, {
                    // showPreloader: true 
                })
                .then((response) => {
                    let spindleBrands = response.data.spindleBrands;
                    let spindleTypes = response.data.spindleTypes;
                    let spindles = response.data.spindles;

                    let reset = this.page === 1;
                    if(spindleBrands.length > 0) {
                        this.page++;
                    }

                    this.emitter.emit('infinite-list-load-data', { name: 'spindleBrands', data: spindleBrands, reset: reset });
                    this.emitter.emit('infinite-list-load-data', { name: 'spindleTypes', data: spindleTypes, reset: reset });
                    this.emitter.emit('infinite-list-load-data', { name: 'spindles', data: spindles, reset: reset });
                })
                .catch(() => {});
            },
            reloadLists() {
                this.page = 1;

                if(Boolean(this.spindleBrandNameSearchValue) || Boolean(this.spindleTypeNameSearchValue) || Boolean(this.serialNumberSearchValue)) {
                    this.loadLists();
                }
                else {
                    this.emitter.emit('infinite-list-reload', { name: 'spindleBrands' });
                    this.emitter.emit('infinite-list-reload', { name: 'spindleTypes' });
                    this.emitter.emit('infinite-list-reload', { name: 'spindles' });
                }
            },
            onDisplayDeletedFilterChange(displayDeleted) {
                this.displayDeleted = displayDeleted;
            },
            openSpindleTypeStatsModal(spindle) {
                this.displaySpindleTypeStats = true;
                axios
                    .get(`affairStatusHistory/spindleType/${ spindle.id }`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.currentSpindleTypeHistory = result.data;
                    })
                    .catch(() => {});
            },
            closeSpindleTypeStatsModal() {
                this.displaySpindleTypeStats = false;
                this.currentSpindleTypeHistory.length = 0;
            },
            openSpindleInSubcontractingModal() {
                this.displaySpindleInSubcontractingModal = true;
                axios
                    .get(`spindleType/inSubcontracting`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.spindlesInSubcontracting = result.data;
                    })
                    .catch(() => {});
            },
            closeSpindleInSubcontractingModal() {
                this.spindlesInSubcontracting = null;
                this.displaySpindleInSubcontractingModal = false;
            },
            exportSpindleTypeStats() {
                let rows = [];
                rows.push(['Statut', 'Temps moyen']);
                for (let data of this.currentSpindleTypeHistory) {
                    rows.push([data.statusLabel, data.timeByStatus]);
                }
                let csvContent = rows.map(e => e.join(";")).join("\n");

                this.exportCSV(csvContent, 'export_statut_broche_');
            }
        }
    }
</script>