<style scoped>
    .card {
        flex: 1;
        padding: 0;
        position: relative;
        background-color: white;
        padding: 30px;
        box-shadow: 0 10px 15px -6px rgb(0 20 40 / 35%);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
</style>

<template>
    <div class="card">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Card'
    }
</script>