<style scoped>
    .radiobutton-container {
        flex: 1;
        padding: 0;
        position: relative;
        margin-bottom: 10px;
        margin-left: 15px;
    }

    .radiobutton-container label {
        font-size: 12px;
        margin: 7px 12px 0px 12px;
        transition: 0.1s ease;
        color: rgb(100, 110, 120);
        text-transform: initial;
        position: relative;
        top: 4px;
    }

    .radiobutton-container label.label-medium {
        font-size: 11px;
        margin: 5px 8px 0px 8px;
    }
    
    .radiobutton-container > div:last-child {
        display: flex;
        padding: 10px 0px 0px 10px;
        box-sizing: border-box;
    }

    .invalid-mark {
        box-sizing: border-box;
        color: #ff416b;
        border-radius: 4px;
        position: absolute;
        transition: opacity .3s;
        font-size: 11px;
        font-family: "montserrat";
        height: 100%;
        width: 100%;
        border: 2px solid #ff416b;
    }

    .invalid-mark-message-container {
        position: relative;
        background-color: #ff416b;
        color: white;
        position: absolute;
        width: calc(100% + 4px);
        bottom: -16px;
        z-index: 99;
        border-radius: 0px 0px 4px 4px;
        left: -2px;
    }

    .invalid-mark-message {
        padding: 4px 12px;
    }
</style>

<template>
    <div class="radiobutton-container">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <label v-if="label" :class="{ 'label-medium': isMedium }">{{ label }}</label>
        <div>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RadioButtonContainer',
        props: {
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: null
            },
            displayError: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            modelValue: {
                type: String,
                default: null
            },
            isMedium: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            modelValue: { 
                handler(modelValue) {
                    this.checkField(modelValue);
                }
            },
            required: {
                handler() {
                    this.checkField(this.modelValue)
                }
            }
        },
        mounted() {
            this.checkField(this.modelValue, true);
        },
        methods: {
            checkField(data, init = false) {
                let error = null;

                // On vérifie s'il n'y a pas d'erreur
                if (this.required === true && (data == null || data === '')) {
                    error = `Ce champ est requis`;
                }

                this.$nextTick(() => {
                    // On émet l'événement pour spécifier qu'il y a un changement
                    this.$emit('onChange', {
                        name: this.name,
                        value: data,
                        error: {
                            type: 'validator',
                            message: error
                        },
                        init: init
                    });
                });
            },
        }
    }
</script>