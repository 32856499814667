<style scoped>
.infinite-list {
    overflow-y: auto;
    overflow-y: overlay;
    scrollbar-color: rgb(120, 130, 140);
    scrollbar-width: thin;
}

.infinite-list::-webkit-scrollbar {
    width: 6px;
}

.infinite-list::-webkit-scrollbar-track {
    background: transparent;
}

.infinite-list::-webkit-scrollbar-thumb {
    background: rgb(120, 130, 140);
}

.infinite-list::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 110, 120);
}
</style>

<template>
    <div class="infinite-list" v-on:scroll="handleScroll">
        <slot name="itemContainer" v-for='item in items' :item="item"></slot>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'InfiniteList',
    props: {
        name: {
            type: String,
            default: ''
        },
        apiRoute: {
            type: String,
            default: ''
        },
        apiParams: {
            type: Object,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            items: [],
            page: 1
        };
    },
    beforeMount() {

    },
    mounted() {
        this.launchRequest();

        // Cas particulier du scroll depuis l'extérieur du composant
        this.emitter.on('infinite-list-load', (data) => {
            this.loadEventHandler(data);
        });

        this.emitter.on('infinite-list-load-data', (data) => {
            this.loadDataEventHandler(data);
        });

        this.emitter.on('infinite-list-reload', (data) => {
            this.reloadEventHandler(data);
        });
    },
    unmounted() {
        this.emitter.off('infinite-list-load');
        this.emitter.off('infinite-list-load-data');
        this.emitter.off('infinite-list-reload');
    },
    methods: {
        handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if(scrollTop + clientHeight >= scrollHeight) {
                if(Boolean(this.apiRoute)) {
                    this.launchRequest();
                }
                else {
                    this.$emit('onScrollBottomReached', {});
                }
            }
        },
        launchRequest() {
            let payload = {
                page: this.page,
                numberItems: this.itemsPerPage
            }

            for (let apiParamKey in this.apiParams) {
                if (this.apiParams[apiParamKey] == null) {
                    delete this.apiParams[apiParamKey];
                }
            }

            if(this.apiParams !== null) {
                payload = Object.assign(payload, this.apiParams);
            }

            const params = new URLSearchParams(payload);

            axios.get(this.apiRoute + '?' + params, {
                // showPreloader: true
            })
                .then((response) => {
                    let data = response.data?.data ? response.data.data : response.data;
                    if(data.length > 0) {
                        this.page++;
                    }
                    this.loadData(data);
                })
                .catch(() => {});
        },
        loadData(data) {
            this.items.push(...data);
        },
        reset() {
            this.page = 1;
            this.items = [];
        },
        reload() {
            this.reset();
            if(Boolean(this.apiRoute)) {
                this.launchRequest();
            }
        },
        loadEventHandler(data) {
            if(data.name === this.name) {
                this.launchRequest();
            }
        },
        loadDataEventHandler(data) {
            if(data.name === this.name) {
                if(data.reset) {
                    this.reset();
                }
                this.loadData(data.data);
            }
        },
        reloadEventHandler(data) {
            if(data.name === this.name) {
                this.reload();
            }
        }
    }
}
</script>